var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('h2',{staticClass:"brand-text text-primary ml-1"},[_c('img',{attrs:{"src":require('@/assets/images/logo/logo-main.png'),"height":"60px"}})])]),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Bắt đầu phát triển 🚀 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Tạo tài khoản đơn giản và dễ dàng ")]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Họ và Tên","label-for":"register-username"}},[_c('validation-provider',{attrs:{"name":"Username","vid":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-username","name":"register-username","state":errors.length > 0 ? false:null,"placeholder":"johndoe"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false:null,"placeholder":"john@example.com"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Số điện thoại","label-for":"register-phone"}},[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-select',{staticClass:"w-90",attrs:{"options":_vm.country_codes},model:{value:(_vm.country_code),callback:function ($$v) {_vm.country_code=$$v},expression:"country_code"}})],1),_c('b-col',{attrs:{"cols":"9"}},[_c('validation-provider',{attrs:{"name":"Phone","vid":"phone_number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-phone","name":"register-phone","state":errors.length > 0 ? false:null,"placeholder":"090123456"},model:{value:(_vm.userPhone),callback:function ($$v) {_vm.userPhone=$$v},expression:"userPhone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-form-group',{attrs:{"label-for":"register-password","label":"Mật khẩu"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" Đồng ý với "),_c('b-link',[_vm._v("điều khoản sử dụng")])],1)],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":invalid}},[(_vm.loading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Đăng ký ")],1)],1)]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Đã có tài khoản?")]),_c('b-link',{attrs:{"to":{name:'login'}}},[_c('span',[_vm._v(" Đăng nhập")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }