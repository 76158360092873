module.exports = [
  {
    dial_code: '+84',
  },
  {
    dial_code: '+49',
  },
  {
    dial_code: '+61',
  },
  {
    dial_code: '+1',
  },
]